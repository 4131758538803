import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import Layout from "../components/layout"
import SEO from "../components/seo"
import CourseComponent from "../components/CourseComponent"
import Spinner from "../components/Reusable/Spinner"

import { getCourseById } from "../services/course"
import { getParameterByName } from "../services/utils"
import * as sessionSelectors from "../redux/slices/session/selectors"

const mapStateToProps = state => ({
  user: sessionSelectors.getUser(state),
})

const mapDispatchToProps = {}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

const CoursePage = ({ user }) => {
  const [id, setId] = useState(-1)
  const [course, setCourse] = useState(null)

  const updateData = () => {
    getCourseById(id)
      .then(resp => {
        setCourse(resp.data)
      })
      .catch(error => {
        console.log(error)
      })
  }

  useEffect(() => {
    setId(getParameterByName("id"))
  }, [])

  useEffect(() => {
    if (id >= 0) {
      updateData()
    }
  }, [id, user])

  return (
    <Layout>
      <SEO title={course?.title ? course.title : ""} />
      {course ? (
        <CourseComponent course={course} />
      ) : (
        <div
          style={{
            width: "100%",
            height: "90vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spinner />
        </div>
      )}
    </Layout>
  )
}

export default withConnect(CoursePage)
